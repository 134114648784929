<template>
  <div class="container h-100">
    <div class="row h-100">
      <div
        class="col-12 d-flex flex-column align-items-center justify-content-center text-center"
      >
        <h1 class="mb-4">{{ errorText }}</h1>

        <router-link to="/" class="btn btn-primary">
          Вернуться на главную
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',

  metaInfo() {
    return {
      title: this.errorText,
    }
  },

  props: {
    errorText: {
      type: String,
      default: 'Страница не найдена',
    },
  },
}
</script>
