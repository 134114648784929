const routes = [
  {
    path: '/income-diagnostics/applications',
    name: 'admin-income-diagnostics-applications',
    meta: {
      accessControlGroup: 'income-diagnostics',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-income-diagnostics" */ '@admin/pages/admin/incomeDiagnostics/IncomeDiagnosticsApplications.vue'
      ),
  },
  {
    path: '/income-diagnostics/settings/schools',
    name: 'admin-income-diagnostics-settings',
    meta: {
      accessControlGroup: 'income-diagnostics',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-income-diagnostics" */ '@admin/pages/admin/incomeDiagnostics/IncomeDiagnosticsSettings.vue'
      ),
  },
  {
    path: '/income-diagnostics/settings/school/:schoolId(\\d+)',
    name: 'admin-income-the-diagnostics-setting',
    meta: {
      accessControlGroup: 'income-diagnostics',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-income-diagnostics" */ '@admin/pages/admin/incomeDiagnostics/TheIncomeDiagnosticsSetting.vue'
      ),
  },
]

export default routes
