<template>
  <LoaderFullScreen v-if="isLoading" />

  <div v-else :class="$style.page">
    <div :class="$style.container" class="py-3">
      <div :class="$style.sidebar" class="px-3">
        <UiKitNavigation />

        <a :href="logoutUrl" class="btn btn-lg btn-primary mt-4 w-100">
          <span class="mr-2">Выход</span>
          <i class="fa fa-sign-out-alt"></i>
        </a>

        <portal-target name="left" />
      </div>

      <div :class="$style.content" class="px-3">
        <transition mode="out-in" name="fade">
          <main :class="$style.main">
            <router-view />
          </main>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import UiKitNavigation from '@admin/ui/admin/UiKitNavigation.vue'
import LoaderFullScreen from 'CommonComponents/LoaderFullScreen.vue'

export default {
  name: 'TheMain',

  components: {
    LoaderFullScreen,
    UiKitNavigation,
  },

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    logoutUrl() {
      return `//${window.domains.main}/logout`
    },
  },
}
</script>

<style lang="scss" module>
@import '~bootstrap/scss/mixins';
@import '~@admin/scss/functions';
@import '~@admin/scss/bootstrap/variables';

.container {
  display: flex;
  height: 100vh;
  overflow: hidden;

  > * {
    height: 100%;
    overflow-x: hidden;
  }

  @include media-breakpoint-down(sm) {
    display: block;
    overflow-y: scroll;

    > * {
      height: auto;
    }
  }
}

.sidebar {
  width: 325px;
  overflow-y: scroll;

  @include media-breakpoint-down(sm) {
    width: 100%;
    overflow-y: visible;
  }
}

.content {
  position: relative;
  flex: 1;
  overflow-y: auto;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.main {
  position: relative;
  z-index: 0;
  height: 100%;

  > * {
    height: 100%;
  }
}
</style>
