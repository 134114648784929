<template>
  <span
    v-if="hasItems"
    :class="{ active: isVisible }"
    class="header"
    @click="processClick"
  >
    <span v-if="item.icon" class="uiKit-navigation-icon">
      <span :class="item.icon"></span>
    </span>

    <span class="uiKit-navigation-label">
      <router-link
        v-if="item.routeName"
        ref="link"
        :to="{ name: item.routeName }"
        >{{ item.text }}</router-link
      >

      <span v-else>{{ item.text }}</span>
    </span>

    <span v-if="!isVisible" class="uiKit-navigation-arrow">
      <i class="fas fa-caret-down iconArrowDown"></i>
    </span>

    <span v-else class="uiKit-navigation-arrow">
      <i class="fas fa-caret-up iconArrowUp"></i>
    </span>

    <span v-if="item.notifications">
      <span :class="$style.counter">{{ item.notifications }}</span>
    </span>
  </span>

  <router-link v-else :to="{ name: item.routeName }" class="header">
    <span v-if="item.icon" class="uiKit-navigation-icon">
      <span :class="item.icon"></span>
    </span>

    <span class="uiKit-navigation-label">{{ item.text }}</span>

    <span v-if="item.notifications">
      <span :class="$style.counter">{{ item.notifications }}</span>
    </span>

    <template v-if="hasAppend">
      <component :is="appendComponent" v-if="appendComponent" />
    </template>
  </router-link>
</template>

<script>
import { isNil } from 'lodash'

export default {
  name: 'UiKitNavigationGroupHeader',

  props: {
    /**
     * Current group visibility
     */
    visible: {
      type: Boolean,
      required: true,
    },

    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      clonedVisible: this.$props.visible,
    }
  },

  computed: {
    /**
     * Is current group visible
     *
     * @returns {boolean}
     */
    isVisible() {
      return this.clonedVisible
    },

    hasItems() {
      return this.item.items?.length > 0
    },

    hasAppend() {
      return !isNil(this.item.append)
    },

    appendComponent() {
      return this.item.append?.component
    },
  },

  watch: {
    visible: {
      handler() {
        // Sync local data with a prop
        this.clonedVisible = this.$props.visible
      },

      deep: false,
    },
  },

  methods: {
    /**
     * Show group
     */
    show() {
      this.clonedVisible = true
    },

    /**
     * Hide group
     */
    hide() {
      this.clonedVisible = false
    },

    /**
     * Toggle group visibility
     */
    toggle() {
      if (!this.isVisible) {
        this.show()
      } else {
        this.hide()
      }
    },

    /**
     * Toggle group visibility on header click
     *
     * @param {Event} e
     */
    processClick(e) {
      if (e.target !== this.$refs.link?.$el) {
        this.toggle()
        this.$emit('toggle', this.clonedVisible)
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@admin/scss/functions';
@import '~@admin/scss/bootstrap/variables';

$componentSelector: '.uiKit-navigation';

#{$componentSelector} {
  &-group {
    > .header {
      position: relative;
      display: flex;
      line-height: 120%;
      color: var(--uiKit-navigation-headerColorInactive);
      text-decoration: inherit;
      cursor: pointer;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      @include font-size($fontSize);

      &:hover {
        color: $gray-100;
      }

      #{$componentSelector}-icon {
        width: 20px + 15px;
        color: var(--uiKit-navigation-iconColorInactive);
      }

      &.active #{$componentSelector}-label {
        color: var(--uiKit-navigation-labelColorActive);
      }

      &.active #{$componentSelector}-icon {
        color: $blue;
      }
    }

    #{$componentSelector}-label {
      display: inline-block;
      width: 100%;

      a {
        color: inherit;
        text-decoration: inherit;
      }
    }

    #{$componentSelector}-arrow {
      font-size: 10px;

      .iconArrowDown {
        color: var(--uiKit-navigation-iconColorInactive);
      }

      .iconArrowUp {
        color: $blue;
      }
    }
  }
}
</style>

<style lang="scss" module>
@import '~@admin/scss/bootstrap/variables';

.counter {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  color: var(--ui-uiKitNavigation-notifyColor);
  background-color: $pink;
  border-radius: 50%;
}
</style>
