var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isMasked
    ? _c(
        "ImaskInput",
        _vm._b(
          {
            staticClass: "form-control",
            attrs: {
              disabled: _vm.disabled,
              mask: _vm.mask,
              placeholder: _vm.placeholder,
              required: _vm.required,
              type: _vm.type,
            },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          },
          "ImaskInput",
          _vm.$attrs,
          false
        )
      )
    : _vm.type === "checkbox"
    ? _c(
        "input",
        _vm._b(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.modelValue,
                expression: "modelValue",
              },
            ],
            staticClass: "form-control",
            attrs: {
              disabled: _vm.disabled,
              placeholder: _vm.placeholder,
              required: _vm.required,
              type: "checkbox",
            },
            domProps: {
              checked: Array.isArray(_vm.modelValue)
                ? _vm._i(_vm.modelValue, null) > -1
                : _vm.modelValue,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.modelValue,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.modelValue = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.modelValue = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.modelValue = $$c
                }
              },
            },
          },
          "input",
          _vm.$attrs,
          false
        )
      )
    : _vm.type === "radio"
    ? _c(
        "input",
        _vm._b(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.modelValue,
                expression: "modelValue",
              },
            ],
            staticClass: "form-control",
            attrs: {
              disabled: _vm.disabled,
              placeholder: _vm.placeholder,
              required: _vm.required,
              type: "radio",
            },
            domProps: { checked: _vm._q(_vm.modelValue, null) },
            on: {
              change: function ($event) {
                _vm.modelValue = null
              },
            },
          },
          "input",
          _vm.$attrs,
          false
        )
      )
    : _c(
        "input",
        _vm._b(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.modelValue,
                expression: "modelValue",
              },
            ],
            staticClass: "form-control",
            attrs: {
              disabled: _vm.disabled,
              placeholder: _vm.placeholder,
              required: _vm.required,
              type: _vm.type,
            },
            domProps: { value: _vm.modelValue },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.modelValue = $event.target.value
              },
            },
          },
          "input",
          _vm.$attrs,
          false
        )
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }