import { compareDesc, parseISO } from 'date-fns'
import { cloneDeep } from 'lodash'

export default {
  hasEmailLogs(state) {
    return state.emailLogs.length > 0
  },

  emailLogsSortedDesc(state) {
    return cloneDeep(state.emailLogs).sort((a, b) =>
      compareDesc(parseISO(a.timestamp), parseISO(b.timestamp)),
    )
  },

  lastEmailLog(state, getters) {
    if (getters.hasEmailLogs) {
      return getters.emailLogsSortedDesc[0]
    }

    return null
  },
}
