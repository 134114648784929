import AxiosHelper from 'Helpers/AxiosHelper'
import VueHelper from 'Helpers/VueHelper'
import { get } from 'lodash'
import Vue from 'vue'

// TODO: Remove "defaultsData" from setFieldErrors().
// TODO: Cleanup all templates.
/**
 * Set field errors and show global notifications.
 *
 * @example
 * import { NotifyMixin } from 'Helpers/mixins/NotifyMixin'
 *
 * new Vue({
 *   render: h => h({
 *     mixins: [NotifyMixin],
 *   }),
 * })
 *
 * this.notifyMethods().process(
 *     ApiEnrollment.putRequestRejected(
 *       this.enrollmentRequestId,
 *       this.detail,
 *     ).then(() => {
 *       this._change("REQUEST_REJECTED")
 *     })
 * )
 *
 * <uiKit-formField :errors="getFieldErrorByPath(errors, 'path.to.error')" />
 */
export const NotifyMixin = Vue.extend({
  data() {
    return {
      /** Field errors */
      errors: null,
    }
  },

  methods: {
    notifyMethods() {
      return {
        // TODO: implement
        /**
         * Show global error
         *
         * @param {string} message
         * @returns {boolean}
         */
        showError: (message = 'Не удалось получить данные') => {
          Vue.toasted.show(message, {
            action: {
              text: 'OK',
              class: 'toasted-error--close',
              onClick: (e, toast) => {
                toast.goAway(0)
              },
            },
            type: 'error',
          })

          return false
        },

        /**
         * Show global success
         *
         * @param {string} message
         * @todo implement
         * @returns {boolean}
         */
        showSuccess: (message = 'Операция выполнена успешно') => {
          Vue.toasted.show(message, {
            duration: 4000,
            type: 'success',
          })

          return false
        },

        /**
         * Process HTTP-request (action), set field errors,
         * show global notification when needed.
         *
         * @param {Promise} action
         * @param {function():void} onError
         * @param {string} successMessage
         * @returns {Promise}
         */
        process: (
          action,
          onError = function () {},
          successMessage = 'Операция выполнена успешно',
        ) => {
          this.notifyMethods().clearFieldErrors()

          return action
            .then(() => this.notifyMethods().showSuccess(successMessage))
            .catch((error) => {
              AxiosHelper.processError(
                error,
                (message) => {
                  this.notifyMethods().showError(message)
                },
                (data) => {
                  this.notifyMethods().setFieldErrors(data)
                  onError()
                },
              )
            })
        },

        /**
         * Update errors data-object.
         *
         * @description `this.fields` should be declared at created().
         * @param {Object} data
         */
        setFieldErrors: (data = {}) => {
          if (this.fields) {
            VueHelper.defaultsData(data, this.fields)
          }

          this.errors = data
        },

        /**
         * Clear errors object
         */
        clearFieldErrors: () => {
          this.notifyMethods().setFieldErrors()
        },
      }
    },

    /**
     * @param {Object} data
     * @param {string} path
     * @returns {string|null}
     */
    getFieldErrorByPath: (data, path) => {
      if (Array.isArray(path)) {
        for (let i = 0; i < path.length; i++) {
          const result = get(data, path[i], null)

          if (result) {
            return result
          }
        }
      }

      return get(data, path, null)
    },
  },
})
