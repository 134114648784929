import { getActiveModule } from 'Helpers/accessControl/module'

export const setAbilityByIncomeDiagnostics = ({
  activeModules,
  routes,
  permission,
  helpers,
}) => {
  const moduleIncomeDiagnostics = getActiveModule(
    activeModules,
    'income-diagnostics',
  )

  if (moduleIncomeDiagnostics !== undefined) {
    const routeNames = routes.get('income-diagnostics')

    if (permission === 'income_diagnostics') {
      helpers.allowReadMenuItemForRoutes(routeNames)

      return true
    }

    if (permission === 'income_diagnostics_applications') {
      helpers.allowReadRoutes(['admin-income-diagnostics-applications'])

      return true
    }

    if (permission === 'income_diagnostics_settings') {
      helpers.allowReadRoutes([
        'admin-income-diagnostics-settings',
        'admin-income-the-diagnostics-setting',
      ])

      return true
    }
  }

  return false
}
