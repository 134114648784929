import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import axios from 'axios'
import { RequestCounter } from 'Helpers/RequestCounter'

export default class AxiosHelper {
  /**
   * @param {boolean} withCredentials
   * @param {Object} xHeaders - кастомные заголовки
   * @param onResponseInterceptorFulfilled
   * @param onResponseInterceptorRejected
   */
  constructor(
    withCredentials = true,
    xHeaders = {},
    onResponseInterceptorFulfilled = (res) => res,
    onResponseInterceptorRejected = (err) => {
      throw err
    },
  ) {
    this.axios = axios.create({
      withCredentials,
    })

    // Middleware для request
    this.axios.interceptors.request.use(
      (config) => {
        RequestCounter.increase()

        return config
      },
      (err) => {
        RequestCounter.decrease()

        return Promise.reject(err)
      },
    )

    // Middleware для response
    this.axios.interceptors.response.use(
      (res) => {
        RequestCounter.decrease()

        return onResponseInterceptorFulfilled(res)
      },
      (err) => {
        RequestCounter.decrease()

        return onResponseInterceptorRejected(err)
      },
    )

    Object.keys(xHeaders).forEach((xHeader) => {
      this.axios.defaults.headers.common[xHeader] = xHeaders[xHeader]
    })
  }

  /**
   * @param {{}} error
   * @param {Function} showErrorHandler
   * @param {Function} onErrorHandler
   * @returns {Function}
   */
  static processError(
    error,
    showErrorHandler = function () {},
    onErrorHandler = function () {},
  ) {
    const showError = (message) => showErrorHandler(message)

    if (!error.response) {
      showError('Не удалось выполнить операцию')
      throw error
    }

    const {
      response: { data, status },
    } = error

    if (
      status === HttpStatusCodes.UnprocessableEntity ||
      status === HttpStatusCodes.Conflict ||
      status === HttpStatusCodes.BadRequest ||
      status === HttpStatusCodes.IblsDebug
    ) {
      if (!data) {
        return showError('Ошибка валидации данных')
      }

      onErrorHandler(data)

      return showError(
        data.message ? data.message : 'Проверьте данные на наличие ошибок',
      )
    }

    return showError('Неизвестная ошибка')
  }

  /**
   * @param {string} url
   * @param {Object} config - Axios config
   * @returns {Promise}
   * @public
   */
  GET(url, config = {}) {
    return new Promise((resolve, reject) => {
      this.axios
        .get(url, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * @param {string} url
   * @param {Object} data
   * @param {Object} config - Axios config
   * @returns {Promise}
   * @public
   */
  POST(url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      this.axios
        .post(url, data, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * @param {string} url
   * @param {Object} data
   * @param {Object} config - Axios config
   * @returns {Promise}
   * @public
   */
  PUT(url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      this.axios
        .put(url, data, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * @param {string} url
   * @param {Object} data
   * @param {Object} config - Axios config
   * @returns {Promise}
   * @public
   */
  PATCH(url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      this.axios
        .patch(url, data, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * @param {string} url
   * @param {Object} config - Axios config
   * @returns {Promise}
   * @public
   */
  DELETE(url, config = {}) {
    return new Promise((resolve, reject) => {
      this.axios
        .delete(url, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * @param {string} url
   * @param {Object} config - Axios config
   * @returns {Promise}
   * @public
   */
  HEAD(url, config = {}) {
    return new Promise((resolve, reject) => {
      this.axios
        .head(url, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
