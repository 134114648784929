import ApiEnrollmentContractEmailLog from 'ApiRest/Api/Enrollment/Contract/EmailLog'

export default {
  async fetchEmailLogs({ commit }, enrollmentContractId) {
    const { data } = await ApiEnrollmentContractEmailLog.get(
      enrollmentContractId,
    )

    commit('setEmailLogs', data)
  },
}
