<template>
  <ImaskInput
    v-if="isMasked"
    v-model="modelValue"
    v-bind="$attrs"
    class="form-control"
    :disabled="disabled"
    :mask="mask"
    :placeholder="placeholder"
    :required="required"
    :type="type"
  />

  <input
    v-else
    v-model="modelValue"
    v-bind="$attrs"
    class="form-control"
    :disabled="disabled"
    :placeholder="placeholder"
    :required="required"
    :type="type"
  />
</template>

<script>
import { isNull } from 'lodash'
import { IMaskComponent } from 'vue-imask'

export default {
  name: 'UiKitInput',

  components: {
    ImaskInput: IMaskComponent,
  },

  inheritAttrs: false,

  props: {
    mask: {
      type: null,
      default: null,
    },

    placeholder: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    value: {
      type: [String, Number],
      default: '',
    },

    required: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isMasked() {
      return this.mask !== null
    },

    modelValue: {
      get() {
        return isNull(this.value) ? '' : String(this.value)
      },

      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
