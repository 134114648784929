<template>
  <multiselect
    :label="name"
    :placeholder="placeholder"
    :allow-empty="allowEmpty"
    :show-labels="false"
    :track-by="id"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span slot="noResult">{{ noResult }}</span>
    <span slot="noOptions">{{ noOptions }}</span>
  </multiselect>
</template>

<script>
import multiselect from 'vue-multiselect'

export default {
  name: 'MultiselectRu',

  components: {
    multiselect,
  },

  inheritAttrs: false,

  props: {
    name: {
      type: String,
      default: 'name',
    },

    placeholder: {
      type: String,
      default: 'Выбор',
    },

    allowEmpty: {
      type: Boolean,
      default: false,
    },

    id: {
      type: String,
      default: 'id',
    },

    noOptions: {
      type: String,
      default: 'Список пуст',
    },

    noResult: {
      type: String,
      default: 'Значение не найдено',
    },
  },
}
</script>
