import LayoutAdminMain from '@admin/layouts/AdminMain.vue'
import administrating from '@admin/routes/admin/administrating'
import adminMain from '@admin/routes/admin/adminMain'
import fullTimeStatement from '@admin/routes/admin/fullTimeStatement'
import { supportRoutes } from '@admin/routes/admin/support'

import analytics from './analytics'
import certification from './certification'
import client from './client'
import contract from './contract'
import ctp from './ctp'
import event from './event'
import familyCertification from './familyCertification'
import homework from './homework'
import incomeDiagnostics from './incomeDiagnostics'
import library from './library'
import news from './news'
import onlineLesson from './onlineLesson'
import payment from './payment'
import price from './price'
import productManagement from './productManagement'
import rating from './rating'
import request from './request'
import settings from './settings'
import statement from './statement'
import student from './student'
import test from './test'
import wiki from './wiki'

const routes = {
  path: '/',
  component: LayoutAdminMain,

  children: [
    ...analytics,
    ...administrating,
    ...adminMain,
    ...certification,
    ...familyCertification,
    ...client,
    ...contract,
    ...ctp,
    ...event,
    ...library,
    ...incomeDiagnostics,
    ...news,
    ...onlineLesson,
    ...payment,
    ...price,
    ...productManagement,
    ...request,
    ...rating,
    ...settings,
    ...statement,
    ...fullTimeStatement,
    ...student,
    ...test,
    ...homework,
    ...wiki,
    ...supportRoutes,
  ],
}

export default routes
