const routes = [
  {
    path: '/payments/invoices',
    name: 'admin-payment-invoices',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-payment-invoices" */ '@admin/pages/admin/payment/PaymentInvoices.vue'
      ),
  },
  {
    path: '/payments/invoices/create',
    name: 'admin-the-payment-invoice-create',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-payment-invoice" */ '@admin/pages/admin/payment/ThePaymentInvoice.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/payments/invoices/:invoiceId(\\d+)',
    name: 'admin-the-payment-invoice',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-payment-invoice" */ '@admin/pages/admin/payment/ThePaymentInvoice.vue'
      ),
  },
  {
    path: '/payments/promocode-groups',
    name: 'admin-payment-promocode-groups',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-payment-promocode-groups" */ '@admin/pages/admin/payment/PromocodeGroups.vue'
      ),
  },
  {
    path: '/payments/promocode-group/create',
    name: 'admin-payment-the-promocode-group-create',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-payment-the-promocode-group" */ '@admin/pages/admin/payment/ThePromocodeGroup.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/payments/promocode-group/:promocodeGroupId(\\d+)',
    name: 'admin-payment-the-promocode-group',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-payment-the-promocode-group" */ '@admin/pages/admin/payment/ThePromocodeGroup.vue'
      ),
  },
]

export default routes
