<template>
  <div :class="$style.wiki">
    <div :class="$style.reference">
      <span
        v-show="isLoading"
        class="mr-3 spinner-border spinner-border-sm text-primary"
        role="status"
      ></span>

      <router-link v-if="canReadWiki" :to="wikiUrl">Справка</router-link>

      <ThemeToggle class="ml-3" />
    </div>

    <div :class="$style.name" class="my-sm-2 my-md-0">
      <div v-if="profileFullName" class="text-right">
        {{ profileFullName }}
      </div>

      <div v-if="profileEmail" class="text-secondary ml-1">
        ({{ profileEmail }})
      </div>
    </div>
  </div>
</template>

<script>
import { userActions as AccessControlUserActions } from '@admin/accessControl/userActions'
import ThemeToggle from '@admin/components/admin/ThemeToggle.vue'
import { RequestCounter } from 'Helpers/RequestCounter'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'HeaderInfoBar',

  components: {
    ThemeToggle,
  },

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    ...mapState('admin/navigation', ['profile']),

    ...mapGetters('admin/navigation', ['fullName']),

    canReadWiki() {
      return this.$can(AccessControlUserActions.READ, 'admin-wiki')
    },

    wikiUrl() {
      return {
        name: 'admin-wiki',
      }
    },

    profileFullName() {
      return this.fullName
    },

    profileEmail() {
      return this.profile.email
    },
  },

  created() {
    this.setInitialData()
  },

  methods: {
    setInitialData() {
      RequestCounter.onUpdate((total) => {
        this.isLoading = total > 0
      })
    },
  },
}
</script>

<style lang="scss" module>
@import '~bootstrap/scss/mixins';
@import '~@admin/scss/functions';
@import '~@admin/scss/bootstrap/variables';

.wiki {
  width: 100%;
  margin-top: 0.5rem;
  margin-right: 1rem;
}

.reference {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}

.name {
  display: flex;
  white-space: nowrap;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;

  @include media-breakpoint-down(sm) {
    white-space: normal;
    flex-direction: column;
    align-items: flex-end;
  }
}
</style>
