const routes = [
  {
    path: 'administrating-zoom',
    name: 'admin-administrating-zoom',
    meta: {
      accessControlGroup: 'administrating-zoom',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-administrating" */ '@admin/pages/admin/administrating/StudentsZoom.vue'
      ),
  },
  {
    path: 'administrating-zoom/:studentId(\\d+)',
    name: 'admin-administrating-zoom-the-student',
    meta: {
      accessControlGroup: 'administrating-zoom',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-administrating-zoom-the-student" */ '@admin/pages/admin/administrating/TheStudentZoom.vue'
      ),
  },
]

export default routes
