export const trackersInit = function () {
  /**
   * @param {string} src
   */
  const loadScript = function (src) {
    const currentElement = document.createElement('script')
    const firstElement = document.getElementsByTagName('script')[0]

    currentElement.async = true
    currentElement.src = src
    firstElement.parentNode.insertBefore(currentElement, firstElement)
  }

  if (window.trackers) {
    const idMetrika = window.trackers.metrika
      ? Number(window.trackers.metrika)
      : null
    const idAnalytics = window.trackers.analytics ?? null
    const idGTM = window.trackers.gtm ?? null

    if (idAnalytics !== null) {
      loadScript(`https://www.googletagmanager.com/gtag/js?id=${idAnalytics}`)
      window.dataLayer = window.dataLayer || []
      // eslint-disable-next-line func-names
      window.gtag = function () {
        // Can't use arrow func + destructuring as Google expects
        // arguments objects in dataLayer (not an array of arguments).
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments)
      }

      window.gtag('js', new Date())
      window.gtag('config', idAnalytics)
    }

    if (idGTM !== null) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      })
      loadScript(`https://www.googletagmanager.com/gtm.js?id=${idGTM}`)
    }

    if (idMetrika !== null) {
      window.ym =
        window.ym ||
        // eslint-disable-next-line func-names
        function () {
          window.ym.a = window.ym.a || []
          // eslint-disable-next-line prefer-rest-params
          window.ym.a.push(arguments)
        }

      window.ym.l = 1 * new Date()
      window.ym([
        idMetrika,
        'init',
        {
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
        },
      ])
      loadScript('https://mc.yandex.ru/metrika/tag.js')
    }
  }
}
