import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setProfile(state, { firstName, lastName, middleName, id, email }) {
    state.profile.firstName = firstName
    state.profile.lastName = lastName
    state.profile.middleName = middleName
    state.profile.id = id
    state.profile.email = email
  },

  setNavigation(state, payload) {
    Vue.set(state, 'navigation', payload)
  },
}
