/**
 * @typedef {Object} UiKitNavigation
 * @property {string} id - уникальный id
 * @property {string} icon - Иконка
 * @property {string} text - Текст
 * @property {string} [visible] - Видимость
 * @property {number} [notifications] - Кол-во уведомлений
 * @property {UiKitNavigation[]} [items] - Список вложенных элементов
 */

export default () => ({
  /**
   * @type {UiKitNavigation[]}
   */
  navigation: [
    {
      id: 'admin-main',
      icon: 'fas fa-home',
      text: 'Главная',
      routeName: 'admin-main',
    },

    {
      id: 'admin-library',
      icon: 'fas fa-book-open',
      text: 'Библиотека',
      visible: false,
      items: [
        {
          id: 'admin-library-subjects',
          routeName: 'admin-library-subjects',
          text: 'Предметы',
        },
        {
          id: 'admin-library-training-programs',
          routeName: 'admin-library-training-programs',
          text: 'УМК',
        },
        {
          id: 'admin-library-sections',
          routeName: 'admin-library-sections',
          text: 'Разделы',
        },
        {
          id: 'etag',
          text: 'Теги',
          visible: false,
          items: [
            {
              id: 'admin-library-etag-grade-profiles',
              routeName: 'admin-library-etag-grade-profiles',
              text: 'Профиль класса',
            },
            {
              id: 'admin-library-etag-levels-of-education',
              routeName: 'admin-library-etag-levels-of-education',
              text: 'Наименование уровня образования',
            },
            {
              id: 'admin-library-etag-educational-standarts',
              routeName: 'admin-library-etag-educational-standarts',
              text: 'Наименование образовательного стандарта',
            },
            {
              id: 'admin-library-etag-subject-areas',
              routeName: 'admin-library-etag-subject-areas',
              text: 'Наименование предметной области',
            },
            {
              id: 'admin-library-etag-subjects',
              routeName: 'admin-library-etag-subjects',
              text: 'Наименование учебного предмета',
            },
            {
              id: 'admin-library-etag-subject-sections',
              routeName: 'admin-library-etag-subject-sections',
              text: 'Наименование раздела учебного предмета',
            },
            {
              id: 'admin-library-etag-teaching-levels',
              routeName: 'admin-library-etag-teaching-levels',
              text: 'Уровень преподавания',
            },
            {
              id: 'admin-library-etag-material-kinds',
              routeName: 'admin-library-etag-material-kinds',
              text: 'Вид материала',
            },
            {
              id: 'admin-library-etag-material-types',
              routeName: 'admin-library-etag-material-types',
              text: 'Тип материала',
            },
          ],
        },
        {
          id: 'moderation',
          text: 'Модерация',
          visible: false,
          items: [
            {
              id: 'admin-library-moderation-contents',
              routeName: 'admin-library-moderation-contents',
              text: 'Контент',
            },
            {
              id: 'admin-library-moderation-questions',
              routeName: 'admin-library-moderation-questions',
              text: 'Вопросы',
            },
          ],
        },
        {
          id: 'admin-library-trash',
          text: 'Корзина',
          visible: false,
          items: [
            {
              id: 'admin-library-trash-topics',
              routeName: 'admin-library-trash-topics',
              text: 'Темы',
            },
            {
              id: 'admin-library-trash-contents',
              routeName: 'admin-library-trash-contents',
              text: 'Контент',
            },
            {
              id: 'admin-library-trash-questions',
              routeName: 'admin-library-trash-questions',
              text: 'Вопросы',
            },
          ],
        },
      ],
    },

    {
      id: 'admin-tests-parent',
      icon: 'fas fa-check-double',
      text: 'Тесты',
      visible: false,
      items: [
        {
          id: 'admin-tests',
          text: 'Тесты',
          routeName: 'admin-tests',
        },
        {
          id: 'admin-tests-check',
          text: 'Проверка Тестов',
          routeName: 'admin-tests-check',
        },
        {
          id: 'admin-homeworks',
          text: 'Проверка домашних заданий',
          routeName: 'admin-homeworks',
        },
        {
          id: 'admin-tests-payment',
          text: 'Оплата Тестов',
          routeName: 'admin-tests-payment',
        },
      ],
    },

    {
      id: 'admin-ctps',
      icon: 'fas fa-tasks',
      text: 'КТП',
      routeName: 'admin-ctps',
    },

    {
      id: 'admin-certifications',
      icon: 'fas fa-graduation-cap',
      text: 'Аттестация',
      routeName: 'admin-certifications',
      visible: false,
      items: [
        {
          id: 'admin-the-certification-create',
          routeName: 'admin-the-certification-create',
          text: 'Добавить',
        },
      ],
    },

    {
      id: 'admin-family-certifications-parent',
      icon: 'fas fa-graduation-cap',
      text: 'Аттестации СО',
      visible: false,
      items: [
        {
          id: 'admin-family-certifications',
          routeName: 'admin-family-certifications',
          text: 'Аттестации',
        },
        {
          id: 'admin-family-certifications-schedule',
          routeName: 'admin-family-certifications-schedule',
          text: 'Расписание Аттестаций',
        },
        {
          id: 'admin-family-certifications-statement',
          routeName: 'admin-family-certification-statement',
          text: 'Ведомость',
        },
      ],
    },

    {
      id: 'admin-income-diagnostics',
      icon: 'fas fa-graduation-cap',
      text: 'Входящая диагностика',
      visible: false,
      items: [
        {
          id: 'admin-income-diagnostics-applications',
          routeName: 'admin-income-diagnostics-applications',
          text: 'Заявки',
        },
        {
          id: 'admin-income-diagnostics-settings',
          routeName: 'admin-income-diagnostics-settings',
          text: 'Настройки',
        },
      ],
    },

    {
      id: 'admin-statement',
      icon: 'fas fa-address-book',
      text: 'Ведомость',
      routeName: 'admin-the-statement',
      visible: false,
    },

    {
      id: 'admin-the-full-time-statement',
      icon: 'fas fa-address-book',
      text: 'Ведомость ОО',
      routeName: 'admin-the-full-time-statement',
      visible: false,
    },

    {
      id: 'admin-analytics',
      icon: 'fas fa-chart-bar',
      text: 'Аналитика',
      routeName: 'admin-analytics',
      visible: false,
      items: [
        {
          id: 'admin-analytics-student-enrolled',
          routeName: 'admin-analytics-student-enrolled',
          text: 'Отчёт по зачисленным',
        },

        {
          id: 'admin-analytics-ctp',
          routeName: 'admin-analytics-ctp',
          text: 'Аналитика по КТП',
        },

        {
          id: 'admin-analytics-library',
          routeName: 'admin-analytics-library',
          text: 'Аналитика по библиотеке',
        },
      ],
    },

    {
      id: 'admin-students',
      icon: 'fas fa-user-graduate',
      text: 'Ученики',
      routeName: 'admin-students',
      visible: false,
      items: [
        {
          id: 'admin-students-demo',
          routeName: 'admin-students-demo',
          text: 'Демо-ученики',
        },
        {
          id: 'admin-students-educont',
          routeName: 'admin-students-educont',
          text: 'Educont',
        },
        {
          id: 'admin-student-group-distribution',
          routeName: 'admin-student-group-distribution',
          text: 'Распределение по группам',
        },
        {
          id: 'admin-student-transfer',
          routeName: 'admin-student-transfer',
          text: 'Перевод из потока в поток',
        },
        {
          id: 'admin-student-streams',
          routeName: 'admin-student-streams',
          text: 'Потоки',
        },
        {
          id: 'admin-student-groups',
          routeName: 'admin-student-groups',
          text: 'Группы',
        },
        {
          id: 'admin-students-trash',
          routeName: 'admin-students-trash',
          text: 'Корзина',
        },
      ],
    },

    {
      id: 'admin-news',
      icon: 'fas fa-newspaper',
      text: 'Новости',
      routeName: 'admin-news',
    },

    {
      id: 'admin-events',
      icon: 'fas fa-calendar-alt',
      text: 'События',
      routeName: 'admin-events',
    },

    {
      id: 'admin-rating',
      icon: 'fas fa-chart-line',
      text: 'Рейтинг',
      routeName: 'admin-rating',
    },

    {
      id: 'admin-online-lessons',
      icon: 'fas fa-globe',
      text: 'Online-уроки',
      routeName: 'admin-online-lessons',
      visible: false,
      items: [
        {
          id: 'admin-online-lesson-rooms',
          routeName: 'admin-online-lesson-rooms',
          text: 'Управление online-комнатами',
        },
      ],
    },

    {
      id: 'admin-enrollment-requests',
      icon: 'fas fa-file',
      text: 'Заявки',
      routeName: 'admin-enrollment-requests',
      visible: false,
      items: [
        {
          id: 'admin-enrollment-requests-draft',
          routeName: 'admin-enrollment-requests-draft',
          text: 'Черновики',
        },

        {
          id: 'admin-enrollment-requests-trash',
          routeName: 'admin-enrollment-requests-trash',
          text: 'Корзина',
        },
      ],
    },

    {
      id: 'admin-enrollment-contracts',
      icon: 'fas fa-file-alt',
      text: 'Договоры',
      routeName: 'admin-enrollment-contracts',
    },

    {
      id: 'admin-family-certification-requests',
      icon: 'fas fa-file-alt',
      text: 'Заявки на аттестацию СО',
      routeName: 'admin-family-certification-requests',
    },

    {
      id: 'admin-clients',
      icon: 'fas fa-user-tie',
      text: 'Клиенты',
      routeName: 'admin-clients',
      visible: false,
      items: [
        {
          id: 'admin-leads',
          routeName: 'admin-leads',
          text: 'Лиды',
        },
        {
          id: 'admin-clients-trash',
          routeName: 'admin-clients-trash',
          text: 'Корзина',
        },
      ],
    },

    {
      id: 'admin-product-management-parent',
      icon: 'fas fa-shopping-bag',
      text: 'Управление продуктами',
      visible: false,
      items: [
        {
          id: 'admin-product-management-products',
          routeName: 'admin-product-management-products',
          text: 'Продукты',
        },
        {
          id: 'admin-product-management-analytics',
          routeName: 'admin-product-management-analytics',
          text: 'Аналитика',
        },
      ],
    },

    {
      id: 'admin-payment-parent',
      icon: 'fas fa-coins',
      text: 'Платежи',
      visible: false,
      items: [
        {
          id: 'admin-payment-invoices',
          routeName: 'admin-payment-invoices',
          text: 'Счета',
        },
        {
          id: 'admin-payment-promocode-groups',
          routeName: 'admin-payment-promocode-groups',
          text: 'Промокоды',
        },
      ],
    },

    {
      id: 'admin-price-parent',
      icon: 'fas fa-money-bill-wave',
      text: 'Цены',
      visible: false,
      items: [
        {
          id: 'admin-price-education-parent',
          text: 'Обучение',
          visible: false,
          items: [
            {
              id: 'admin-price-education-extramural-parent',
              text: 'Заочное',
              visible: false,
              items: [
                {
                  id: 'admin-price-extramural-subsidy',
                  routeName: 'admin-price-extramural-subsidy',
                  text: 'Для субсидий',
                },
                {
                  id: 'admin-price-extramural-individual-subsidy',
                  routeName: 'admin-price-extramural-individual-subsidy',
                  text: 'ИУП субсидия',
                },
                {
                  id: 'admin-price-extramural-paid',
                  routeName: 'admin-price-extramural-paid',
                  text: 'Платное',
                },
                {
                  id: 'admin-price-extramural-individual-paid',
                  routeName: 'admin-price-extramural-individual-paid',
                  text: 'ИУП платно',
                },
              ],
            },
          ],
        },
      ],
    },

    {
      id: 'admin-support',
      icon: 'fas fa-headset',
      text: 'Поддержка',
      routeName: 'admin-support',
      visible: false,
      notifications: 0,
      append: {
        component: () =>
          import(
            /* webpackChunkName: "admin-questions-count" */ '@admin/components/admin/support/question/QuestionsCount.vue'
          ),
      },
    },

    {
      id: 'admin-settings-parent',
      icon: 'fas fa-sliders-h',
      text: 'Настройки',
      visible: false,
      items: [
        {
          id: 'admin-settings-schools',
          routeName: 'admin-settings-schools',
          text: 'Школы',
        },
        {
          id: 'admin-family-educations',
          routeName: 'admin-family-educations',
          text: 'Семейное обучение',
        },
        {
          id: 'admin-settings-academic-years',
          routeName: 'admin-settings-academic-years',
          text: 'Учебный Год',
        },
        {
          id: 'admin-settings-timetables',
          routeName: 'admin-settings-timetables',
          text: 'Расписания',
        },
        {
          id: 'admin-settings-holidays',
          routeName: 'admin-settings-holidays',
          text: 'Праздники',
        },
        {
          id: 'admin-settings-partners',
          routeName: 'admin-settings-partners',
          text: 'Партнеры',
        },
        {
          id: 'admin-settings-roles',
          routeName: 'admin-settings-roles',
          text: 'Роли сотрудников',
        },
        {
          id: 'admin-settings-users',
          routeName: 'admin-settings-users',
          text: 'Сотрудники',
        },
        {
          id: 'admin-settings-wiki',
          routeName: 'admin-settings-wiki',
          text: 'Настройки справки',
        },
      ],
    },

    {
      id: 'admin-administrating-parent',
      icon: 'fas fa-user-cog',
      text: 'Администрирование',
      visible: false,
      items: [
        {
          id: 'admin-administrating-zoom',
          routeName: 'admin-administrating-zoom',
          text: 'Zoom',
        },
      ],
    },
  ],

  profile: {
    firstName: null,
    lastName: null,
    middleName: null,
    id: null,
    email: null,
  },

  askQuestionCounters: {},
})
