import HttpHeaders from 'Api/const/HttpHeaders'

/**
 * @typedef {Record} PaginationParams
 * @property {number} page - Номер текущей страницы
 * @property {number} rowsPerPage - Количество элементов на страницу (выбранное)
 * @property {number[]} rowsPerPageOptions - Список опций для выбора количества элементов на страницу
 * @property {null|number} pagesTotal - Общее количество страниц
 * @property {null|number} rowsTotal - Общее количество элементов
 * @property {null|number} rowsOnPage - Количество элементов на странице (по факту)
 */

/**
 * Конфигурация CompositePagination
 */
export class Pagination {
  /**
   * @param {PaginationParams} params
   */
  constructor({
    page = 1,
    rowsPerPage = 10,
    rowsPerPageOptions = [10, 20, 40, 60],
    pagesTotal = null,
    rowsTotal = null,
    rowsOnPage = null,
  } = {}) {
    this.page = page
    this.rowsPerPage = rowsPerPage
    this.rowsPerPageOptions = rowsPerPageOptions
    this.pagesTotal = pagesTotal
    this.rowsTotal = rowsTotal
    this.rowsOnPage = rowsOnPage
  }

  /**
   * @param {Record<string,string>} headers
   */
  updateFromHeaders(headers) {
    this.pagesTotal =
      Number(headers[HttpHeaders.PAGINATION_PAGES_TOTAL]) || null
    this.rowsTotal = Number(headers[HttpHeaders.PAGINATION_ROWS_TOTAL]) || null
    this.rowsOnPage =
      Number(headers[HttpHeaders.PAGINATION_ROWS_ON_PAGE]) || null
  }

  /**
   * @param {number} value
   */
  setPage(value) {
    this.page = value
  }

  /**
   * @param {number} value
   */
  setRowsPerPage(value) {
    this.rowsPerPage = value
  }
}
