/**
 * @param {string} goalName
 * @param {string|null} customTrackerId
 */
const reachGoalYandexMetrika = function (goalName, customTrackerId = null) {
  let trackerId = window?.trackers?.metrika

  if (customTrackerId !== null) {
    trackerId = customTrackerId
  }

  if (window.ym && trackerId) {
    window.ym(trackerId, 'reachGoal', goalName)
  }
}

/**
 * @param {string} goalName
 */
const reachGoalGoogleTagManager = function (goalName) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: goalName,
    })
  }
}

/**
 * @param {string} goalName
 */
const reachGoal = function (goalName) {
  if (
    process.iblsEnv.NODE_ENV === 'production' &&
    window.location.host === 'new.iblschool.ru'
  ) {
    reachGoalYandexMetrika(goalName)
    reachGoalGoogleTagManager(goalName)
  }
}

export class WebAnalyticsHelper {
  /**
   * https://new.iblschool.ru/registration
   */
  static goalRegistration() {
    reachGoal('registration')
  }

  /**
   * https://new.iblschool.ru/demo-registration
   */
  static goalRegistrationDemo() {
    reachGoal('registrationdemo')
  }

  /**
   * https://new.iblschool.ru/client/invoices
   */
  static goalPromocode() {
    reachGoal('promokod')
  }

  /**
   * https://new.iblschool.ru/client/demo-access
   */
  static goalDemoAccessForm() {
    reachGoal('demodostupForm')
  }

  /**
   * https://new.iblschool.ru/client/requests/new
   */
  static goalDataContractExtramural() {
    reachGoal('dannieDogovorZO')
  }

  /**
   * https://new.iblschool.ru/client/requests/new
   */
  static goalDataClientExtramural() {
    reachGoal('dannieKlientZO')
  }

  /**
   * https://new.iblschool.ru/client/requests/new
   */
  static goalDataStudentExtramural() {
    reachGoal('dannieYchenikaZO')
  }

  /**
   * https://new.iblschool.ru/client/requests/new
   */
  static goalDataContractFamily() {
    reachGoal('dannieDogovorSem')
  }

  /**
   * https://new.iblschool.ru/client/requests/new
   * TODO: typo in goal name?
   */
  static goalDataClientFamily() {
    reachGoal('dannieKlienSem')
  }

  /**
   * https://new.iblschool.ru/client/requests/new
   */
  static goalDataStudentFamily() {
    reachGoal('dannieYchenikaSem')
  }

  /**
   * https://new.iblschool.ru/client/requests/new
   * TODO: нужно вызывать на шаге оферта или проверка данных?
   */
  static goalDataCheckFamily() {
    reachGoal('dannieProvSem')
  }
}
