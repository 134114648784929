var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "transition-group",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.isLoading
            ? _c("LoaderFullScreen", { key: "loader" })
            : [
                _c("Theme", { key: "theme" }),
                _vm._v(" "),
                _c("router-view", { key: "layout" }),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }