import { merge, omit } from 'lodash'

/**
 * @typedef {Object} AgGridColDefFuncParams
 * @property {Object} node - Узел
 * @property {*} data - Элемент
 * @property {Object} api - API таблицы AG Grid
 * @property {Object} column - Колонка AG Grid
 * @property {?} newValue - Новое значение
 * @property {?} oldValue - Старое значение
 */

/**
 * @typedef {Object} AgGridColDefConfig
 * @property {string} [field]
 * @property {string} [colId]
 * @property {()=>number} [colSpan]
 * @property {'left'|'right'} [pinned]
 * @property {boolean} [filter]
 * @property {boolean} [resizable]
 * @property {boolean} [sortable]
 * @property {null|'asc'|'desc'} [sort]
 * @property {(null|'asc'|'desc')[]} [sortingOrder]
 * @property {(valueA:*,valueB:*,nodeA:Object,nodeB:Object,isInverted:boolean)=>0|1|-1} [comparator]
 * @property {boolean} [editable]
 * @property {number} [flex]
 * @property {number} [minWidth]
 * @property {number} [maxWidth]
 * @property {number} [width]
 * @property {number} [fixedWidth]
 * @property {string} [headerName]
 * @property {string} [headerComponent]
 * @property {Object} [headerComponentParams]
 * @property {(params:AgGridColDefFuncParams)=>AgGridCellRenderer} [cellRendererSelector]
 * @property {(params:AgGridColDefFuncParams)=>string} [valueGetter]
 * @property {(params:AgGridColDefFuncParams)=>boolean} [valueSetter]
 * @property {(params:AgGridColDefFuncParams)=>string} [valueFormatter]
 * @property {(params:AgGridColDefFuncParams)=>string} [valueParser]
 * @property {(params:AgGridColDefFuncParams)=>void} [onCellValueChanged]
 * @property {(params:AgGridColDefFuncParams)=>void} [onCellContextMenu]
 * @property {boolean} [checkboxSelection]
 * @property {boolean} [headerCheckboxSelection]
 * @property {boolean} [headerCheckboxSelectionFilteredOnly]
 * @property {boolean} [suppressMenu]
 * @property {boolean} [suppressColumnsToolPanel]
 * @property {()=>boolean} [suppressHeaderKeyboardEvent]
 */

/**
 * Конфигурация колонки таблицы AG Grid
 */
export class AgGridColDef {
  /**
   * @param {AgGridColDefConfig} config
   */
  constructor(config) {
    merge(this, omit(config, ['fixedWidth']))

    this.setDefault(config)
  }

  /**
   * @private
   * @param {AgGridColDefConfig} config
   */
  setDefault(config) {
    this.flex = config.flex ?? 1

    if (config.checkboxSelection === undefined) {
      this.setDefaultProps(config)
    }

    if (config.fixedWidth) {
      this.setFixedWidth(config.fixedWidth)
    }

    if (config.minWidth) {
      this.width = config.minWidth
    }

    if (config.valueSetter !== undefined) {
      this.editable = true
    }

    this.suppressHeaderKeyboardEvent = () => true
  }

  /**
   * @private
   * @param {AgGridColDefConfig} config
   */
  setDefaultProps(config) {
    this.sortable = config.sortable ?? false
    this.filter = config.filter ?? false
    this.editable = config.editable ?? false
  }

  /**
   * @private
   * @param {number} value
   */
  setFixedWidth(value) {
    this.resizable = false
    this.flex = 0
    this.minWidth = value
    this.maxWidth = value
    this.width = value
  }
}
