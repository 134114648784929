import ApiBase from 'ApiRest/ApiBase'

export default new (class Permissions extends ApiBase {
  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  get() {
    return this._GET(`/user/permissions`)
  }
})()
