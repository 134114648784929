import { AgGridColDef } from '@admin/models/agGrid/AgGridColDef'
import { LibraryEtagTableOptions } from '@admin/models/libraryEtagTables/LibraryEtagTableOptions'
import ApiOption from 'ApiRest/Api/Option'

export class LibraryEtagTableSubjectSectionsOptions extends LibraryEtagTableOptions {
  columnDefs = [
    new AgGridColDef({
      headerName: 'ID',
      valueGetter: ({ data }) => data.id,
      fixedWidth: 70,
    }),

    new AgGridColDef({
      headerName: 'Актуальность',
      headerTooltip: 'Актуальность',
      valueGetter: ({ data }) => (data.isActual ? '✓' : '×'),
      fixedWidth: 70,
    }),

    new AgGridColDef({
      headerName: 'ID Educont',
      valueGetter: ({ data }) => data.externalId,
    }),

    new AgGridColDef({
      headerName: 'ID тeга предмета',
      valueGetter: ({ data }) => data.etagSubjectId,
    }),

    new AgGridColDef({
      headerName: 'Наименование',
      valueGetter: ({ data }) => data.name,
    }),
  ]

  pageTitle = 'Наименование раздела учебного предмета'

  fetchItems = () => ApiOption.getEtagSubjectSections()
}
