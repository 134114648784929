// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiKitButton__button__3Day {\n  position: relative;\n}\n.UiKitButton__loading__1WE1 .UiKitButton__label__5Dsa {\n  opacity: 0;\n}\n.UiKitButton__spinner__1qUn {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  margin: auto;\n}", "",{"version":3,"sources":["webpack://./webpack/@admin/ui/admin/UiKitButton.vue"],"names":[],"mappings":"AA8GA;EACE,kBAAA;AA7GF;AAiHE;EACE,UAAA;AA9GJ;AAkHA;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,YAAA;AA/GF","sourcesContent":["\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n.button {\n  position: relative;\n}\n\n.loading {\n  .label {\n    opacity: 0;\n  }\n}\n\n.spinner {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "UiKitButton__button__3Day",
	"loading": "UiKitButton__loading__1WE1",
	"label": "UiKitButton__label__5Dsa",
	"spinner": "UiKitButton__spinner__1qUn"
};
export default ___CSS_LOADER_EXPORT___;
