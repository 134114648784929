import { getActiveModule } from 'Helpers/accessControl/module'

export const setAbilityByModuleOnlineLesson = ({
  activeModules,
  routes,
  permission,
  helpers,
}) => {
  /**
   * @type {{
   *   settings: {
   *     'conference-type': Object
   *   }
   * }|undefined}
   */
  const moduleOnlineLesson = getActiveModule(activeModules, 'online-lesson')

  if (moduleOnlineLesson !== undefined) {
    const routeNames = routes.get('online-lesson')

    if (permission === 'menu_online_lessons') {
      helpers.allowReadMenuItemForRoutes(routeNames)

      return true
    }

    if (permission === 'online_lessons') {
      helpers.allowReadRoutes(routeNames)

      return true
    }

    if (permission === 'online_lessons_part_update') {
      helpers.allowReadRoutes(routeNames)
      helpers.allowUpdateRoutes(routeNames)

      return true
    }

    if (permission === 'online_lessons_full_update') {
      helpers.allowCrudRoutes(routeNames)

      return true
    }
  }

  return false
}
