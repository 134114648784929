import { render, staticRenderFns } from "./HeaderInfoBar.vue?vue&type=template&id=36607ac2&"
import script from "./HeaderInfoBar.vue?vue&type=script&lang=js&"
export * from "./HeaderInfoBar.vue?vue&type=script&lang=js&"
import style0 from "./HeaderInfoBar.vue?vue&type=style&index=0&lang=scss&module=true&"

var cssModules = {}
var disposed = false

function injectStyles (context) {
  if (disposed) return
  
        cssModules["$style"] = (style0.locals || style0)
        Object.defineProperty(this, "$style", {
          configurable: true,
          get: function () {
            return cssModules["$style"]
          }
        })
      
}


  module.hot && module.hot.dispose(function (data) {
    disposed = true
  })



        module.hot && module.hot.accept(["./HeaderInfoBar.vue?vue&type=style&index=0&lang=scss&module=true&"], function () {
          var oldLocals = cssModules["$style"]
          if (oldLocals) {
            var newLocals = require("./HeaderInfoBar.vue?vue&type=style&index=0&lang=scss&module=true&")
            if (JSON.stringify(newLocals) !== JSON.stringify(oldLocals)) {
              cssModules["$style"] = newLocals
              require("/var/www/dev-may/frontend/node_modules/vue-hot-reload-api/dist/index.js").rerender("36607ac2")
            }
          }
        })

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/dev-may/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36607ac2')) {
      api.createRecord('36607ac2', component.options)
    } else {
      api.reload('36607ac2', component.options)
    }
    module.hot.accept("./HeaderInfoBar.vue?vue&type=template&id=36607ac2&", function () {
      api.rerender('36607ac2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "webpack/@admin/components/admin/HeaderInfoBar.vue"
export default component.exports