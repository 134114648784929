import { ApiSupportQuestionAssignedCount } from 'ApiRest/Api/Support/Question/AssignedCount'
import { ApiSupportQuestionCounter } from 'ApiRest/Api/Support/Question/Counter'
import { ApiSupportQuestionNewCount } from 'ApiRest/Api/Support/Question/NewCount'

export default {
  async fetchQuestionsCounter({ commit }) {
    const { data } = await ApiSupportQuestionCounter.get()

    commit('setQuestionsCounter', {
      path: 'counter',
      value: data,
    })
  },

  async fetchQuestionsNewCount({ commit }) {
    const { data } = await ApiSupportQuestionNewCount.get()

    commit('setQuestionsCounter', {
      path: 'newCount',
      value: data,
    })
  },

  async fetchQuestionsAssignedCount({ commit }) {
    const { data } = await ApiSupportQuestionAssignedCount.get()

    commit('setQuestionsCounter', {
      path: 'assignedCount',
      value: data,
    })
  },
}
