import { AgGridColDef } from '@admin/models/agGrid/AgGridColDef'

/**
 * @property {AgGridColDef[]} columnDefs
 * @property {string} pageTitle
 */
export class LibraryEtagTableOptions {
  columnDefs = [
    new AgGridColDef({
      headerName: 'ID',
      valueGetter: ({ data }) => data.id,
      fixedWidth: 70,
    }),

    new AgGridColDef({
      headerName: 'Актуальность',
      headerTooltip: 'Актуальность',
      valueGetter: ({ data }) => (data.isActual ? '✓' : '×'),
      fixedWidth: 70,
    }),

    new AgGridColDef({
      headerName: 'ID Educont',
      valueGetter: ({ data }) => data.externalId,
    }),

    new AgGridColDef({
      headerName: 'Наименование',
      valueGetter: ({ data }) => data.name,
    }),
  ]

  pageTitle = ''

  fetchItems() {
    throw new Error('Необходимо имплементировать метод fetchItems')
  }
}
