var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "body", class: { hidden: !_vm.isVisible } },
    _vm._l(_vm.item.items, function (subItem, subItemIndex) {
      return _c(
        "li",
        { key: subItemIndex, staticClass: "uiKit-navigation-item" },
        [
          !subItem.items
            ? _c(
                "router-link",
                {
                  staticClass: "uiKit-navigation-link",
                  attrs: { to: { name: subItem.routeName } },
                },
                [_vm._v("\n      " + _vm._s(subItem.text) + "\n    ")]
              )
            : _c("UiKitNavigationGroup", {
                attrs: { item: subItem },
                on: {
                  toggle: function ($event) {
                    return _vm.processToggle(subItemIndex, $event)
                  },
                },
              }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }