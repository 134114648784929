import ApiOption from 'ApiRest/Api/Option'
import ApiTheIndividualPaid from 'ApiRest/Api/Price/Extramural/Individual/Paid'
import ApiIndividualPaid from 'ApiRest/Api/Price/Extramural/Individual/Paid/List'
import ApiIndividualPaidGrades from 'ApiRest/Api/Price/Extramural/Individual/Paid/Option/Grade'
import ApiTheIndividualSubsidy from 'ApiRest/Api/Price/Extramural/Individual/Subsidy'
import ApiIndividualSubsidy from 'ApiRest/Api/Price/Extramural/Individual/Subsidy/List'
import ApiIndividualSubsidyGrades from 'ApiRest/Api/Price/Extramural/Individual/Subsidy/Option/Grade'
import ApiThePaid from 'ApiRest/Api/Price/Extramural/Paid'
import ApiPaid from 'ApiRest/Api/Price/Extramural/Paid/List'
import ApiPaidGrades from 'ApiRest/Api/Price/Extramural/Paid/Option/Grade'
import ApiTheSubsidy from 'ApiRest/Api/Price/Extramural/Subsidy'
import ApiSubsidy from 'ApiRest/Api/Price/Extramural/Subsidy/List'
import ApiSubsidyGrades from 'ApiRest/Api/Price/Extramural/Subsidy/Option/Grade'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchOptionSchools({ commit }) {
    const { data } = await ApiOption.getSchools()

    commit('setOption', {
      key: 'schools',
      value: data,
    })
  },

  async fetchOptionDevelopmentPeriods({ commit }) {
    const { data } = await ApiOption.getDevelopmentPeriods()

    commit('setOption', {
      key: 'developmentPeriods',
      value: data,
    })
  },

  async fetchSubsidyPrices({ commit }, parameters) {
    const { data } = await ApiSubsidy.get(parameters)

    commit('setSubsidyPrices', data)
  },

  async fetchTheSubsidyGrades({ commit }, parameters) {
    const { data } = await ApiSubsidyGrades.get(parameters)

    commit('setOption', {
      key: 'grades',
      value: data,
    })
  },

  async fetchTheSubsidyPrice({ commit }, subsidyId) {
    const { data } = await ApiTheSubsidy.get(subsidyId)

    commit('setTheSubsidyPrice', data)
  },

  async createTheSubsidyPrice({ commit }, payload) {
    const { data } = await ApiTheSubsidy.post(payload)

    commit('setTheSubsidyPrice', data)
  },

  async updateTheSubsidyPrice({ commit }, { subsidyId, payload }) {
    const { data } = await ApiTheSubsidy.patch(subsidyId, payload)

    commit('setTheSubsidyPrice', data)
  },

  async fetchPaidPrices({ commit }, params) {
    const { data } = await ApiPaid.get(params)

    commit('setPaidPrices', data)
  },

  async fetchThePaidGrades({ commit }, params) {
    const { data } = await ApiPaidGrades.get(params)

    commit('setOption', {
      key: 'grades',
      value: data,
    })
  },

  async fetchThePaidPrice({ commit }, paidId) {
    const { data } = await ApiThePaid.get(paidId)

    commit('setThePaidPrice', data)
  },

  async createThePaidPrice({ commit }, payload) {
    const { data } = await ApiThePaid.post(payload)

    commit('setThePaidPrice', data)
  },

  async updateThePaidPrice({ commit }, { paidId, thePaidPrice }) {
    const { data } = await ApiThePaid.patch(paidId, thePaidPrice)

    commit('setThePaidPrice', data)
  },

  async fetchIndividualSubsidyPrices({ commit }, params) {
    const { data } = await ApiIndividualSubsidy.get(params)

    commit('setIndividualSubsidyPrices', data)
  },

  async fetchTheIndividualSubsidyGrades({ commit }, params) {
    const { data } = await ApiIndividualSubsidyGrades.get(params)

    commit('setOption', {
      key: 'grades',
      value: data,
    })
  },

  async fetchTheIndividualSubsidyPrice({ commit }, subsidyId) {
    const { data } = await ApiTheIndividualSubsidy.get(subsidyId)

    commit('setTheIndividualSubsidyPrice', data)
  },

  async createTheIndividualSubsidyPrice({ commit }, payload) {
    const { data } = await ApiTheIndividualSubsidy.post(payload)

    commit('setTheIndividualSubsidyPrice', data)
  },

  async updateTheIndividualSubsidyPrice({ commit }, { subsidyId, payload }) {
    const { data } = await ApiTheIndividualSubsidy.patch(subsidyId, payload)

    commit('setTheIndividualSubsidyPrice', data)
  },

  async fetchIndividualPaidPrices({ commit }, params) {
    const response = await ApiIndividualPaid.get(params)

    commit('setIndividualPaidPrices', response.data)

    return response
  },

  async fetchTheIndividualPaidGrades({ commit }, params) {
    const { data } = await ApiIndividualPaidGrades.get(params)

    commit('setOption', {
      key: 'grades',
      value: data,
    })
  },

  async fetchTheIndividualPaidPrice({ commit }, paidId) {
    const { data } = await ApiTheIndividualPaid.get(paidId)

    commit('setTheIndividualPaidPrice', data)
  },

  async createTheIndividualPaidPrice({ commit }, payload) {
    const { data } = await ApiTheIndividualPaid.post(payload)

    commit('setTheIndividualPaidPrice', data)
  },

  async updateTheIndividualPaidPrice({ commit }, { paidId, payload }) {
    const { data } = await ApiTheIndividualPaid.patch(paidId, payload)

    commit('setTheIndividualPaidPrice', data)
  },
}
