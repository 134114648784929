var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container h-100" }, [
    _c("div", { staticClass: "row h-100" }, [
      _c(
        "div",
        {
          staticClass:
            "col-12 d-flex flex-column align-items-center justify-content-center text-center",
        },
        [
          _c("h1", { staticClass: "mb-4" }, [_vm._v(_vm._s(_vm.errorText))]),
          _vm._v(" "),
          _c(
            "router-link",
            { staticClass: "btn btn-primary", attrs: { to: "/" } },
            [_vm._v("\n        Вернуться на главную\n      ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }