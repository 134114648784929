var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-date-picker",
        _vm._g(
          _vm._b(
            {
              attrs: {
                locale: "ru",
                is24hr: "",
                "is-dark": _vm.isDarkTheme,
                popover: _vm.popover,
                masks: _vm.masks,
                "model-config": _vm.modelConfig,
                "update-on-input": false,
                columns: _vm.columns,
                "is-expanded": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var inputValue = ref.inputValue
                    var togglePopover = ref.togglePopover
                    return [
                      _vm.isRange
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c(
                                  "UiKitFormField",
                                  {
                                    attrs: {
                                      label: _vm.startDateLabel,
                                      required: _vm.required,
                                      errors: _vm.errors.startDate,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "date-picker" }, [
                                      _c(
                                        "div",
                                        { staticClass: "input-group" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "date-picker__button date-picker__calendar-button input-group-prepend",
                                              attrs: { disabled: _vm.disabled },
                                              on: { click: togglePopover },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "input-group-text",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-calendar",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            staticClass: "form-control",
                                            attrs: {
                                              placeholder:
                                                _vm.startDatePlaceholder,
                                              disabled: _vm.disabled,
                                            },
                                            domProps: {
                                              value: inputValue.start,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.setDate(
                                                  $event,
                                                  "start"
                                                )
                                              },
                                              click: togglePopover,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm.localDate.start && !_vm.disabled
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "date-picker__button date-picker__clear-button input-group-append",
                                                  on: { click: _vm.resetDate },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "input-group-text",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-times",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c(
                                  "UiKitFormField",
                                  {
                                    attrs: {
                                      label: _vm.endDateLabel,
                                      required: _vm.required,
                                      errors: _vm.errors.endDate,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "date-picker" }, [
                                      _c(
                                        "div",
                                        { staticClass: "input-group" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "date-picker__button date-picker__calendar-button input-group-prepend",
                                              attrs: { disabled: _vm.disabled },
                                              on: { click: togglePopover },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "input-group-text",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-calendar",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            staticClass: "form-control",
                                            attrs: {
                                              placeholder:
                                                _vm.endDatePlaceholder,
                                              disabled: _vm.disabled,
                                            },
                                            domProps: { value: inputValue.end },
                                            on: {
                                              change: function ($event) {
                                                return _vm.setDate(
                                                  $event,
                                                  "end"
                                                )
                                              },
                                              click: togglePopover,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm.localDate.end && !_vm.disabled
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "date-picker__button date-picker__clear-button input-group-append",
                                                  on: { click: _vm.resetDate },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "input-group-text",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-times",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _c(
                            "UiKitFormField",
                            {
                              attrs: {
                                label: _vm.dateLabel,
                                required: _vm.required,
                                errors: _vm.errors.date,
                              },
                            },
                            [
                              _c("div", { staticClass: "date-picker" }, [
                                _c("div", { staticClass: "input-group" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "date-picker__button date-picker__calendar-button input-group-prepend",
                                      attrs: { disabled: _vm.disabled },
                                      on: { click: togglePopover },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "input-group-text" },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-calendar",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    staticClass: "form-control",
                                    attrs: {
                                      placeholder: _vm.datePlaceholder,
                                      disabled: _vm.disabled,
                                    },
                                    domProps: { value: inputValue },
                                    on: {
                                      change: _vm.setDate,
                                      click: togglePopover,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.localDate && !_vm.disabled
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "date-picker__button date-picker__clear-button input-group-append",
                                          on: { click: _vm.resetDate },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "input-group-text" },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-times",
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.localDate,
                callback: function ($$v) {
                  _vm.localDate = $$v
                },
                expression: "localDate",
              },
            },
            "v-date-picker",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }