import { RESET_STATE } from 'Helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setSchools(state, payload) {
    state.schools = payload
  },

  setServices(state, payload) {
    state.services = payload
  },

  setConfiguration(state, payload) {
    state.configuration = payload
  },
}
