<template>
  <ul class="body" :class="{ hidden: !isVisible }">
    <li
      v-for="(subItem, subItemIndex) in item.items"
      :key="subItemIndex"
      class="uiKit-navigation-item"
    >
      <router-link
        v-if="!subItem.items"
        :to="{ name: subItem.routeName }"
        class="uiKit-navigation-link"
      >
        {{ subItem.text }}
      </router-link>

      <UiKitNavigationGroup
        v-else
        :item="subItem"
        @toggle="($event) => processToggle(subItemIndex, $event)"
      />
    </li>
  </ul>
</template>

<script>
import { cloneDeep } from 'lodash'

export default {
  name: 'UiKitNavigationGroupBody',

  components: {
    UiKitNavigationGroup: () =>
      import('@admin/ui/admin/UiKitNavigationGroup.vue'),
  },

  props: {
    /**
     * Current item and its children
     */
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      clonedItem: cloneDeep(this.$props.item),
    }
  },

  computed: {
    /**
     * Is current group visible
     *
     * @returns {boolean}
     */
    isVisible() {
      return this.clonedItem.visible
    },
  },

  watch: {
    item: {
      handler() {
        // Sync local data with a prop
        this.clonedItem = cloneDeep(this.$props.item)
      },

      deep: true,
    },
  },

  methods: {
    /**
     * Update subgroups visibility
     *
     * @param {number} itemIndex
     * @param {Object} data
     */
    processToggle(itemIndex, data) {
      this.hideAllGroups()
      this.clonedItem.items[itemIndex] = data
      this.$emit('toggle', cloneDeep(this.clonedItem))
    },

    /**
     * Hide all groups
     */
    hideAllGroups() {
      const { items } = this.clonedItem

      Object.keys(items).forEach((groupKey) => {
        items[groupKey].visible = false
      })
    },
  },
}
</script>

<style lang="scss">
@use 'sass:math';

@import '~@admin/scss/functions';
@import '~@admin/scss/bootstrap/variables';

$componentSelector: '.uiKit-navigation';

#{$componentSelector} {
  &-group {
    > .body {
      padding: 0;
      margin: 0;
      list-style: none;

      &.hidden {
        display: none;
      }
    }

    #{$componentSelector}-item {
      // Spacing between items
      padding: 20px 0 0;
    }

    #{$componentSelector}-link {
      position: relative;
      display: block;
      padding: 0 0 0 (20px + 15px) + math.div(20px, 1.5);
      line-height: 24px;
      color: var(--uiKit-navigation-headerColorInactive);
      text-decoration: inherit;
      word-break: break-word;

      &::before {
        position: absolute;
        top: 0;
        left: 20px + 15px;
        width: 4px;
        height: 24px;
        content: '';
        background: url('~Static/images/components/uiKit/navigation/bulletInactive.svg')
          center center no-repeat;
      }

      &:hover {
        color: $blue;

        &::before {
          background-image: url('~Static/images/components/uiKit/navigation/bulletActive.svg');
        }
      }
    }
  }
}
</style>
