var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "student-demo" },
    [
      _vm.isLoading
        ? _c("LoadingElement")
        : [
            _c("PageTitle", { attrs: { title: _vm.pageTitle } }),
            _vm._v(" "),
            _c("UiKitContentGroup", [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-4 mb-4" },
                  [
                    _c(
                      "UiKitFormField",
                      {
                        attrs: {
                          errors: _vm.getFieldErrorByPath(
                            _vm.errors,
                            "lastName"
                          ),
                          label: "Фамилия",
                        },
                      },
                      [
                        _c("UiKitInput", {
                          attrs: { placeholder: "Введите фамилию" },
                          model: {
                            value: _vm.lastName,
                            callback: function ($$v) {
                              _vm.lastName = $$v
                            },
                            expression: "lastName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-4 mb-4" },
                  [
                    _c(
                      "UiKitFormField",
                      {
                        attrs: {
                          errors: _vm.getFieldErrorByPath(
                            _vm.errors,
                            "firstName"
                          ),
                          label: "Имя",
                        },
                      },
                      [
                        _c("UiKitInput", {
                          attrs: { placeholder: "Введите имя" },
                          model: {
                            value: _vm.firstName,
                            callback: function ($$v) {
                              _vm.firstName = $$v
                            },
                            expression: "firstName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-4 mb-4" },
                  [
                    _c(
                      "UiKitFormField",
                      {
                        attrs: {
                          errors: _vm.getFieldErrorByPath(
                            _vm.errors,
                            "middleName"
                          ),
                          label: "Отчество",
                        },
                      },
                      [
                        _c("UiKitInput", {
                          attrs: { placeholder: "Введите отчество" },
                          model: {
                            value: _vm.middleName,
                            callback: function ($$v) {
                              _vm.middleName = $$v
                            },
                            expression: "middleName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-4 mb-4" },
                  [
                    _c(
                      "UiKitFormField",
                      {
                        attrs: {
                          errors: _vm.getFieldErrorByPath(
                            _vm.errors,
                            "password"
                          ),
                          label: "Пароль",
                        },
                      },
                      [
                        _c("UiKitInput", {
                          attrs: { placeholder: "Введите Пароль" },
                          model: {
                            value: _vm.password,
                            callback: function ($$v) {
                              _vm.password = $$v
                            },
                            expression: "password",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-4 mb-4" },
                  [
                    _c("UiKitDatePicker", {
                      attrs: {
                        mode: "dateTime",
                        "date-label": "Срок демо-доступа до",
                      },
                      model: {
                        value: _vm.demoAccessExpiredAt,
                        callback: function ($$v) {
                          _vm.demoAccessExpiredAt = $$v
                        },
                        expression: "demoAccessExpiredAt",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-4 mb-4" },
                  [
                    _c(
                      "UiKitFormField",
                      {
                        attrs: {
                          errors: _vm.getFieldErrorByPath(_vm.errors, "school"),
                          label: "Школа",
                        },
                      },
                      [
                        _c("MultiselectRu", {
                          attrs: {
                            placeholder: "Школа",
                            options: _vm.options.schools,
                          },
                          on: { input: _vm.getGradesAndEducationType },
                          model: {
                            value: _vm.detail.school,
                            callback: function ($$v) {
                              _vm.$set(_vm.detail, "school", $$v)
                            },
                            expression: "detail.school",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-4 mb-4" },
                  [
                    _c(
                      "UiKitFormField",
                      {
                        attrs: {
                          errors: _vm.getFieldErrorByPath(
                            _vm.errors,
                            "educationType"
                          ),
                          label: "Тип обучения",
                        },
                      },
                      [
                        _c("MultiselectRu", {
                          attrs: {
                            placeholder: "Выберите школу",
                            disabled: _vm.isEducationTypeSelectDisabled,
                            options: _vm.options.educationTypes,
                          },
                          on: { input: _vm.getGradesAndEducationType },
                          model: {
                            value: _vm.detail.educationType,
                            callback: function ($$v) {
                              _vm.$set(_vm.detail, "educationType", $$v)
                            },
                            expression: "detail.educationType",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-4 mb-4" },
                  [
                    _c(
                      "UiKitFormField",
                      {
                        attrs: {
                          errors: _vm.getFieldErrorByPath(_vm.errors, "grade"),
                          label: "Класс",
                        },
                      },
                      [
                        _c("MultiselectRu", {
                          attrs: {
                            placeholder: "Выберите тип обучения",
                            disabled: _vm.isGradeSelectDisabled,
                            options: _vm.options.grades,
                          },
                          model: {
                            value: _vm.grade,
                            callback: function ($$v) {
                              _vm.grade = $$v
                            },
                            expression: "grade",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("hr", { staticClass: "contentGroupHr mb-4" }),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-6 mb-4" },
                  [
                    _c(
                      "UiKitFormField",
                      {
                        attrs: {
                          errors: _vm.getFieldErrorByPath(_vm.errors, "email"),
                          label: "E-mail",
                        },
                      },
                      [
                        _c("UiKitInput", {
                          attrs: { type: "email", placeholder: "Эл. почта" },
                          model: {
                            value: _vm.email,
                            callback: function ($$v) {
                              _vm.email = $$v
                            },
                            expression: "email",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-6 mb-4" },
                  [
                    _c(
                      "UiKitFormField",
                      {
                        attrs: {
                          errors: _vm.getFieldErrorByPath(_vm.errors, "phone"),
                          label: "Телефон",
                        },
                      },
                      [
                        _c("UiKitInput", {
                          attrs: { placeholder: "Телефон" },
                          model: {
                            value: _vm.phone,
                            callback: function ($$v) {
                              _vm.phone = $$v
                            },
                            expression: "phone",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c("UiKitButton", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.canCreateStudentDemo,
                        expression: "canCreateStudentDemo",
                      },
                    ],
                    staticClass: "btn-success btn-lg",
                    attrs: {
                      label: "Создать",
                      loading: _vm.isLoadingBtns.create,
                      disabled: _vm.isLoadingBtns.create,
                    },
                    on: { click: _vm.create },
                  }),
                  _vm._v(" "),
                  _c("UiKitButton", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.canUpdateStudentDemo,
                        expression: "canUpdateStudentDemo",
                      },
                    ],
                    staticClass: "btn-success btn-lg",
                    attrs: {
                      label: "Сохранить",
                      loading: _vm.isLoadingBtns.save,
                      disabled: _vm.isLoadingBtns.save,
                    },
                    on: { click: _vm.save },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-4" }, [
              _c("div", { staticClass: "col-lg-6 col-md-12" }, [
                _c("h4", [_vm._v(_vm._s(_vm.parentType))]),
                _vm._v(" "),
                _c("table", { staticClass: "table contentGroup" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th"),
                      _vm._v(" "),
                      _c("th", [_vm._v("ID")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("ФИО")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Телефон")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _vm.detail.client
                        ? [
                            _c("tr", [
                              _c(
                                "td",
                                { staticClass: "td-fixed-eye" },
                                [
                                  _c("router-link", {
                                    staticClass: "fas fa-eye detail-link",
                                    attrs: {
                                      target: "_blank",
                                      rel: "noopener noreferrer",
                                      to: {
                                        name: "admin-the-client",
                                        params: {
                                          clientId: _vm.detail.client.id,
                                        },
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("td", { staticClass: "td-fixed-id" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.detail.client.id) +
                                    "\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.getFullName(_vm.detail.client))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(_vm.detail.client.phone)),
                              ]),
                            ]),
                          ]
                        : _vm.detail.lead
                        ? [
                            _c("tr", [
                              _c(
                                "td",
                                { staticClass: "td-fixed-eye" },
                                [
                                  _c("router-link", {
                                    staticClass: "fas fa-eye detail-link",
                                    attrs: {
                                      target: "_blank",
                                      rel: "noopener noreferrer",
                                      to: {
                                        name: "admin-the-lead",
                                        params: {
                                          leadId: _vm.detail.lead.id,
                                        },
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("td", { staticClass: "td-fixed-id" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.detail.lead.id) +
                                    "\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.getFullName(_vm.detail.lead))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.detail.lead.phone))]),
                            ]),
                          ]
                        : [
                            _c("tr", [
                              _c("td", { staticClass: "td-fixed-eye" }),
                              _vm._v(" "),
                              _c("td", { staticClass: "td-fixed-id" }, [
                                _c("i", { staticClass: "fa fa-minus" }),
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v("Нет данных")]),
                              _vm._v(" "),
                              _c("td"),
                            ]),
                          ],
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }