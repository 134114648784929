import ApiConfiguration from 'ApiRest/Api/IncomeDiagnostics/Configuration'
import ApiOption from 'ApiRest/Api/Option'
import ApiSchoolList from 'ApiRest/Api/School/List'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchSchools({ commit }) {
    const options = {
      hasModule: 'income-diagnostics',
    }

    const { data } = await ApiSchoolList.get(options)

    commit('setSchools', data)
  },

  async fetchServices({ commit }, id) {
    const { data } = await ApiOption.getInvoiceServices(id)

    commit('setServices', data)
  },

  async fetchConfiguration({ commit }, id) {
    const { data } = await ApiConfiguration.get(id)

    commit('setConfiguration', data)
  },
}
