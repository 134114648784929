const routes = [
  {
    path: 'product-management/products',
    name: 'admin-product-management-products',
    meta: {
      accessControlGroup: 'product-management',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-management-products" */ '@admin/pages/admin/productManagement/Products.vue'
      ),
  },
  {
    path: 'product-management/products/create',
    name: 'admin-product-management-create-product',
    meta: {
      accessControlGroup: 'product-management',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-management-create-product" */ '@admin/pages/admin/productManagement/CreateProduct.vue'
      ),
  },
  {
    path: 'product-management/analytics',
    name: 'admin-product-management-analytics',
    component: () =>
      import(
        /* webpackChunkName: "admin-product-management-analytics" */ '@admin/pages/admin/productManagement/Analytics.vue'
      ),
  },
  {
    path: 'product-management/products/:productId(\\d+)',
    name: 'admin-the-product-management',
    meta: {
      accessControlGroup: 'product-management',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-product-management" */ '@admin/components/admin/productManagement/TheProductDetail.vue'
      ),
  },
]

export default routes
