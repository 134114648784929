var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "uiKit-formField" }, [
    _c("div", { staticClass: "d-flex" }, [
      _vm.label
        ? _c("span", { staticClass: "uiKit-formField-label" }, [
            _vm._v("\n      " + _vm._s(_vm.label) + "\n      "),
            _vm.required
              ? _c("span", { staticClass: "text-pink" }, [_vm._v("*")])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showEraser
        ? _c("i", {
            staticClass: "fas fa-eraser ml-2 uiKit-formField-eraser-icon",
            on: { click: _vm.erase },
          })
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "span",
      { staticClass: "uiKit-formField-value", class: { error: _vm.errors } },
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.error,
            expression: "error",
          },
        ],
        staticClass: "uiKit-formField-error",
      },
      [_vm._v("\n    " + _vm._s(_vm.error) + "\n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }