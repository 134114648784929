import { composeRouteGroups, conditions } from '@admin/accessControl/helpers'
import { setAbilityByIncomeDiagnostics } from '@admin/accessControl/modules/incomeDiagnostics'
import { setAbilityByModuleOnlineLesson } from '@admin/accessControl/modules/onlineLesson'
import { setPermissionByModuleSupport } from '@admin/accessControl/modules/support'
import { setAbilityByMenuPermission } from '@admin/accessControl/setAbilityByMenuPermission'
import { setAbilityByPermissionForRoutesWithoutGroup } from '@admin/accessControl/setAbilityByPermissionForRoutesWithoutGroup'
import { setAbilityByPermissionPerRouteGroup } from '@admin/accessControl/setAbilityByPermissionPerRouteGroup'

const routes = composeRouteGroups()

/**
 * Конвертация пермишена в CASL-правило или набор правил
 *
 * @param {Object} data
 * @param {Object[]} data.activeModules - Массив модулей, доступных для юзера
 * @param {string} data.permission - Пермишен, который проверяем
 * @param {Object} utils - Вспомогательные методы CASL
 * @param {Function} utils.can
 * @returns {boolean}
 */
export const permissionToRules = ({ activeModules, permission }, { can }) => {
  const helpers = conditions({
    can,
  })

  if (
    setAbilityByPermissionPerRouteGroup({
      routes,
      permission,
      helpers,
    })
  ) {
    return true
  }

  // Разрешения по отдельным роутам
  if (
    setAbilityByPermissionForRoutesWithoutGroup({
      permission,
      helpers,
    })
  ) {
    return true
  }

  // Разрешения на "Отображение пунктов главного меню"
  if (setAbilityByMenuPermission(permission, helpers)) {
    return true
  }

  // Модуль "Поддержка"
  if (
    setPermissionByModuleSupport({
      activeModules,
      routes,
      permission,
      helpers,
    })
  ) {
    return true
  }

  // Модуль "Онлайн уроки"
  if (
    setAbilityByModuleOnlineLesson({
      activeModules,
      routes,
      permission,
      helpers,
    })
  ) {
    return true
  }

  // Модуль "Входящая диагностика"
  if (
    setAbilityByIncomeDiagnostics({
      activeModules,
      routes,
      permission,
      helpers,
    })
  ) {
    return true
  }

  return true
}
