var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasItems
    ? _c(
        "span",
        {
          staticClass: "header",
          class: { active: _vm.isVisible },
          on: { click: _vm.processClick },
        },
        [
          _vm.item.icon
            ? _c("span", { staticClass: "uiKit-navigation-icon" }, [
                _c("span", { class: _vm.item.icon }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "uiKit-navigation-label" },
            [
              _vm.item.routeName
                ? _c(
                    "router-link",
                    {
                      ref: "link",
                      attrs: { to: { name: _vm.item.routeName } },
                    },
                    [_vm._v(_vm._s(_vm.item.text))]
                  )
                : _c("span", [_vm._v(_vm._s(_vm.item.text))]),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isVisible
            ? _c("span", { staticClass: "uiKit-navigation-arrow" }, [
                _c("i", { staticClass: "fas fa-caret-down iconArrowDown" }),
              ])
            : _c("span", { staticClass: "uiKit-navigation-arrow" }, [
                _c("i", { staticClass: "fas fa-caret-up iconArrowUp" }),
              ]),
          _vm._v(" "),
          _vm.item.notifications
            ? _c("span", [
                _c("span", { class: _vm.$style.counter }, [
                  _vm._v(_vm._s(_vm.item.notifications)),
                ]),
              ])
            : _vm._e(),
        ]
      )
    : _c(
        "router-link",
        { staticClass: "header", attrs: { to: { name: _vm.item.routeName } } },
        [
          _vm.item.icon
            ? _c("span", { staticClass: "uiKit-navigation-icon" }, [
                _c("span", { class: _vm.item.icon }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "uiKit-navigation-label" }, [
            _vm._v(_vm._s(_vm.item.text)),
          ]),
          _vm._v(" "),
          _vm.item.notifications
            ? _c("span", [
                _c("span", { class: _vm.$style.counter }, [
                  _vm._v(_vm._s(_vm.item.notifications)),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAppend
            ? [
                _vm.appendComponent
                  ? _c(_vm.appendComponent, { tag: "component" })
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }